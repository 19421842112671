import getAlexandriaUrl from '@joybird/joystagram-components/dist/esm/getAlexandriaUrl';

const env = {};

const getGlobalEnv = name => {
  const _env_ = global._env_;

  if (!_env_) {
    return '';
  }

  return _env_[name] ?? '';
};

env.TEMP_UID = 'tuID';
env.VERSION = getGlobalEnv('REACT_APP_VERSION');

env.GRAPHQL_BASE_URL = getAlexandriaUrl();

env.WEBSOCKET_URI = getGlobalEnv('REACT_APP_WEBSOCKET_URI');
env.SENTRY_DSN = getGlobalEnv('REACT_APP_SENTRY_DSN');
env.APP_ROBOTS_META = getGlobalEnv('REACT_APP_ROBOTS_META');

env.ENV_NAME = getGlobalEnv('REACT_APP_ENV_NAME');

/*if (env.DEVELOPMENT) {
    env.VERSION = 'development';
} else if (env.TEST) {
    env.VERSION = 'test';
} else if (env.PRODUCTION) {
    env.VERSION = 'production';
}*/

export default Object.freeze(env);
