// Note: on 27th June 2024, a copy of this file was created with the name
// makeApolloClient.js, and is used in the new Consumer app. A separate copy is
// required due to the number of changes, but the core functionality is still
// shared with this version. If any functional changes are made to this file,
// they should be reflected in the new file as well.

import {
  ApolloClient,
  ApolloLink,
  split,
  HttpLink,
  createHttpLink,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import * as Sentry from '@sentry/react';
import { onError } from '@apollo/client/link/error';

import {
  resolvers,
  createCache,
} from '@joybird/joystagram-components/dist/esm/linkstate';
import {
  getUserFromCookie,
  getUserAccessTokenCookie,
  getUserRefreshTokenCookie,
  setUserAccessTokenCookie,
  setUserRefreshTokenCookie,
  clearUserTokenCookies,
} from '@joybird/joystagram-components/dist/esm/localStorage';
import setHttpContextValue from '@joybird/joystagram-components/dist/esm/setHttpContextValue';
import getHttpContextValue from '@joybird/joystagram-components/dist/esm/getHttpContextValue';
import getAlexandriaUrl from '@joybird/joystagram-components/dist/esm/getAlexandriaUrl';
import isDevServer from '@joybird/joystagram-components/dist/esm/isDevServer';
import isLocalhost from '@joybird/joystagram-components/dist/esm/isLocalhost';

// import 'es6-promise/auto';
import axiosFetch from './axiosFetch';
import env from '../../services/env';
import { isClient } from '../../app/globals';
import { logError } from '../../commons/utils';

const fetchWithRetry = async (url, options) => {
  try {
    return await axiosFetch(url, options);
  } catch (err) {
    if (/ETIMEDOUT|ECONNRESET|Network Error/.test(err.message)) {
      return await axiosFetch(url, options);
    }
    logError(err);
    throw err;
  }
};

export function generateApolloClient() {
  const cache = createCache();

  if (isClient() && window.__APOLLO_STATE__) {
    cache.restore(window.__APOLLO_STATE__);
  }

  const clearUserData = () => {
    clearUserTokenCookies();
  };

  let refreshTokenPromise = null;
  // custom request fetcher for refresh token
  const requestFetch = (uri, options) => {
    let initialResponse;
    const initialRequest = fetchWithRetry(uri, options);
    return initialRequest
      .then(response => {
        initialResponse = response.clone();
        return response.json();
      })
      .then(json => {
        const finalExtensions =
          json && Array.isArray(json) ? json[0].extensions : json.extensions;
        // If sent JWToken is expired
        if (
          (finalExtensions && finalExtensions.meta.tokenExpired) ||
          (getUserFromCookie() &&
            getUserRefreshTokenCookie() &&
            !getUserAccessTokenCookie())
        ) {
          if (!refreshTokenPromise) {
            const user = getUserFromCookie();
            const refreshToken = getUserRefreshTokenCookie();
            if (user && refreshToken) {
              refreshTokenPromise = fetchWithRetry(env.GRAPHQL_BASE_URL, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  operationName: 'refreshToken',
                  query: `mutation refreshToken($input: RefreshTokenInput) {
                    refreshToken(input: $input) {
                      error
                      access_token
                      refresh_token
                    }
                  }`,
                  variables: {
                    input: {
                      userId: user.id,
                      userEmail: user.email,
                      userFirstName: user.first_name,
                      userLastName: user.last_name,
                      userGroupId: user.group_id,
                      refreshToken: refreshToken,
                    },
                  },
                }),
              }).then(response => {
                if (response.ok) {
                  return response.json().then(json => {
                    if (json && json.data && json.data.refreshToken) {
                      const {
                        access_token,
                        refresh_token,
                      } = json.data.refreshToken;
                      if (access_token && refresh_token) {
                        setUserRefreshTokenCookie(refresh_token);
                        setUserAccessTokenCookie(access_token);
                        return access_token;
                      } else {
                        clearUserData();
                      }
                    } else {
                      clearUserData();
                    }
                  });
                } else {
                  clearUserData();
                }
              });
            } else {
              clearUserData();
            }
          }
          if (refreshTokenPromise) {
            return refreshTokenPromise.then(newAccessToken => {
              refreshTokenPromise = null;
              options.headers.Authorization = newAccessToken;
              return fetchWithRetry(uri, options);
            });
          }
        }
        return initialResponse;
      });
  };

  const errorLink = new onError(
    ({ graphQLErrors, networkError, operation }) => {
      try {
        if (graphQLErrors) {
          graphQLErrors.forEach(err => {
            logError(
              '[GraphQL error]:',
              JSON.stringify(err),
              JSON.stringify(operation)
            );
            Sentry.captureException(
              new Error(
                `GraphQL Error${
                  operation?.operationName
                    ? ': ' + operation?.operationName
                    : ''
                }${err?.message ? ': ' + err?.message : ''}`,
                { cause: err }
              )
            );
          });
        }

        if (networkError) {
          logError(
            `[Network error]: ${networkError}`,
            JSON.stringify(networkError),
            JSON.stringify(operation)
          );
        }
      } catch (e) {
        logError(e);
      }
    }
  );

  const traceIdLink = new ApolloLink((operation, forward) => {
    const reqId = getHttpContextValue('reqId');
    const counter = getHttpContextValue('trace-id-counter') || 0;
    if (reqId) {
      operation.setContext(({ headers: previousHeaders }) => ({
        headers: { ...previousHeaders, 'CF-RAY': `${reqId}-${counter}` },
      }));
      setHttpContextValue('trace-id-counter', counter + 1);
    }
    return forward(operation);
  });

  // Add Authentication Header to Apollo client request
  const authLink = new ApolloLink((operation, forward) => {
    const accessToken = getUserAccessTokenCookie();
    if (accessToken) {
      const headers = {};
      if (accessToken && accessToken !== 'undefined') {
        headers['Authorization'] = accessToken;
      }
      if (Object.keys(headers).length > 0) {
        operation.setContext(({ headers: previousHeaders }) => ({
          headers: { ...previousHeaders, ...headers },
        }));
      }
    }

    return forward(operation);
  });
  let httpLinks = [],
    batchHttpLinks = [];
  let apolloConfig = {};
  if (isClient()) {
    //Delete window Apollo State
    delete window.__APOLLO_STATE__;
    // Enables batching of all queries till noBatch context value is encountered
    const batchHttpLink = new BatchHttpLink({
      uri: getAlexandriaUrl(),
      credentials: 'include',
      fetch: requestFetch,
      batchMax: 1,
    });
    httpLinks = ApolloLink.from([
      errorLink,
      authLink,
      new HttpLink({
        uri: getAlexandriaUrl(),
        credentials: 'include',
        fetch: requestFetch,
      }),
    ]);
    batchHttpLinks = ApolloLink.from([errorLink, authLink, batchHttpLink]);
    apolloConfig = {
      assumeImmutableResults: true,
      link: split(
        operation => operation.getContext().noBatch === true,
        httpLinks,
        batchHttpLinks
      ),
      cache,
      connectToDevTools: window._env_.REACT_APP_VERSION === 'development',
      // shouldBatch: true,
      resolvers,
    };
  } else {
    apolloConfig = {
      assumeImmutableResults: true,
      ssrMode: true,
      link: ApolloLink.from([
        errorLink,
        authLink,
        traceIdLink,
        createHttpLink({
          uri: env.GRAPHQL_BASE_URL,
          credentials: 'same-origin',
          fetch: axiosFetch,
        }),
      ]),
      cache,
      resolvers,
    };
  }

  const client = new ApolloClient({
    ...apolloConfig,
    connectToDevTools: isLocalhost() || isDevServer(),
  });

  return client;
}

export default generateApolloClient();
